export const getError = (error) => {
    const errorMessage = "API Error, please try again.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        console.log(error);
        //console.error(`API ${error.config.url} not found`);
        return  errorMessage;
    }

    if (process.env.NODE_ENV === "development") {
        console.log(error);
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
    }

    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return errorMessage;
};

export const titleCase = (value) => {
   const titleCase = value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
   return titleCase
};

export const fullName = (first_name, last_name) => {
    return first_name + ' ' + last_name;
};

export const formatPhone = (phone) => {
    if (!phone) {
        return 'Not Provided';
    }

    const formattedPhone = phone.replace(/\D/g, '');

    if (formattedPhone.length === 10) {
        return `(${formattedPhone.substring(0, 3)}) ${formattedPhone.substring(3, 6)}-${formattedPhone.substring(6)}`;
    } else if (formattedPhone.length > 10) {
        const countryCode = formattedPhone.length > 11 ? `+${formattedPhone.substring(0, formattedPhone.length - 10)} ` : '';
        const numberPart = formattedPhone.substring(formattedPhone.length - 10);
        return `${countryCode}(${numberPart.substring(0, 3)}) ${numberPart.substring(3, 6)}-${numberPart.substring(6)}`;
    } else {
        return 'Invalid phone number';
    }
};


export const formatPhoneForInput = (phone) => {
    const formattedPhone = phone?.replace(/\D/g, '');
    let countryCode = '';
    if (formattedPhone.length > 10) {
        countryCode = `+${formattedPhone.substring(0, formattedPhone.length - 10)} `;
    }
    const phoneNumber = formattedPhone.substring(formattedPhone.length - 10);
    return `${countryCode}(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
};


// shows time if today, only date, not time if before today - used on Tables
// Original formatDate function (now renamed to formatDateOrTime)
export const formatDateOrTime = (input) => {
    try {
        if (input === undefined) {
            console.error('Error formatting timestamp: Input is undefined');
            return '';
        }

        const timestampMatch = input.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}/);

        if (timestampMatch) {
            const timestamp = timestampMatch[0];
            const date = new Date(timestamp?.replace(',', '.'));
            const now = new Date();

            const isBeforeToday = date < now;

            if (isNaN(date.getTime())) {
                console.error(`Error parsing timestamp: ${timestamp}`);
                return input;
            }

            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];

            const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
                ? ` ${date.getFullYear()}`
                : '';

            const formattedDate = isBeforeToday
                ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
                : '';

            const hours = date.getHours();
            const minutes = date.getMinutes();
            const amOrPm = hours >= 12 ? "pm" : "am";
            const formattedHours = hours % 12 || 12;
            const formattedMinutes = minutes.toString().padStart(2, "0");

            return isBeforeToday
                ? formattedDate
                : `${formattedDate} ${formattedHours}:${formattedMinutes}${amOrPm}`;
        }

        const date = new Date(input);
        const now = new Date();

        now.setHours(0, 0, 0, 0);

        const isBeforeToday = date < now;

        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
            ? ` ${date.getFullYear()}`
            : '';

        const formattedDate = isBeforeToday
            ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
            : '';

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amOrPm = hours >= 12 ? "pm" : "am";
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, "0");

        return isBeforeToday
            ? formattedDate
            : `${formattedDate} ${formattedHours}:${formattedMinutes}${amOrPm}`;
    } catch (error) {
        console.error(`Error formatting timestamp: ${error.message}`);
        return input; 
    }
};

// (only returns formatted date without time)
export const formatDate = (input) => {
    try {
        if (input === undefined) {
            console.error('Error formatting timestamp: Input is undefined');
            return '';
        }

        const timestampMatch = input.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}/);

        if (timestampMatch) {
            const timestamp = timestampMatch[0];
            const date = new Date(timestamp.replace(',', '.'));

            if (isNaN(date.getTime())) {
                console.error(`Error parsing timestamp: ${timestamp}`);
                return input;
            }

            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];

            const year = date.getFullYear();
            const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${year}`;

            return formattedDate;
        }

        const date = new Date(input);
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        const year = date.getFullYear();
        const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${year}`;

        return formattedDate;
    } catch (error) {
        console.error(`Error formatting timestamp: ${error.message}`);
        return input; 
    }
};


// shows time including seconds if today, only date, not time if before today - says Today if Today
export const formatDateAndTimeWithSeconds = (input) => {
    try {
        const timestampMatch = input?.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}/);

        if (timestampMatch) {
            const timestamp = timestampMatch[0];
            const date = new Date(timestamp.replace(',', '.'));
            const now = new Date();

            const isBeforeToday = date < now;

            if (isNaN(date.getTime())) {
                console.error(`Error parsing timestamp: ${timestamp}`);
                return input; 
            }

            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];

            const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
                ? ` ${date.getFullYear()}`
                : '';

            const formattedDate = isBeforeToday
                ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
                : '';

            const formatTime = (date) => {
                const hours = date.getHours() % 12 || 12;
                const minutes = date.getMinutes().toString().padStart(2, "0");
                const seconds = date.getSeconds().toString().padStart(2, "0");
                const amOrPm = date.getHours() >= 12 ? "pm" : "am";
                return `${hours}:${minutes}:${seconds}${amOrPm}`;
            };
            
            const formatDateAndTimeWithSeconds = isBeforeToday
                ? formattedDate
                : `${formattedDate} ${formatTime(date)}`;
          
            return formatDateAndTimeWithSeconds;
        }

        const date = new Date(input);
        const now = new Date();

        now.setHours(0, 0, 0, 0);
        
        const isBeforeToday = date < now;
        
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
            ? ` ${date.getFullYear()}`
            : '';

        const formattedDate = isBeforeToday
            ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
            : '';

        const formatTime = (date) => {
            const hours = date.getHours() % 12 || 12;
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");
            const amOrPm = date.getHours() >= 12 ? "pm" : "am";
            return `${hours}:${minutes}:${seconds}${amOrPm}`;
        };
        
        const formatDateAndTimeWithSeconds = isBeforeToday
            ? formattedDate
            : `${formattedDate} ${formatTime(date)}`;
      
        return formatDateAndTimeWithSeconds;
    } catch (error) {
        console.error(`Error in formatDateAndTimeWithSeconds: ${error}`);
        return input;
    }
};


// shows time if today, date AND time if before today - WAS used in InstanceChat, now it's Unix below
export const formatDateAndTime = (input) => {
    try {
        const timestampMatch = input?.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}/);

        if (timestampMatch) {
            const timestamp = timestampMatch[0];
            const date = new Date(timestamp.replace(',', '.'));
            const now = new Date();
        
            const isBeforeToday = date < now;

            if (isNaN(date.getTime())) {
                console.error(`Error parsing timestamp: ${timestamp}`);
                return input; 
            }
            
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];
        
            const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
                ? ` ${date.getFullYear()}`
                : '';
            
            const formattedDate = isBeforeToday
                ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
                : '';
            
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const amOrPm = hours >= 12 ? "pm" : "am";
            const formattedHours = hours % 12 || 12; 
            const formattedMinutes = minutes.toString().padStart(2, "0"); 
            
            const formatDateAndTime = isBeforeToday
                ? `${formattedDate} ${formattedHours}:${formattedMinutes}${amOrPm}`
                : `${formattedHours}:${formattedMinutes}${amOrPm}`;
          
            return formatDateAndTime;
        }

        const date = new Date(input);
        const now = new Date();

        now.setHours(0, 0, 0, 0);
        
        const isBeforeToday = date < now;
        
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
            ? ` ${date.getFullYear()}`
            : '';

        const formattedDate = isBeforeToday
            ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
            : '';

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amOrPm = hours >= 12 ? "pm" : "am";
        const formattedHours = hours % 12 || 12; 
        const formattedMinutes = minutes.toString().padStart(2, "0"); 
        
        const formatDateAndTime = isBeforeToday
            ? `${formattedDate} ${formattedHours}:${formattedMinutes}${amOrPm}`
            : `${formattedHours}:${formattedMinutes}${amOrPm}`;
      
        return formatDateAndTime;
    } catch (error) {
        console.error(`Error in formatDateAndTime: ${error}`);
        return input;
    }
};


// new Unix used in Instance Chat - shows time w seconds, shows date if before today
export function formatDateAndTimeUnix(unixTimestamp) {
    const date = new Date(unixTimestamp);
    const currentDate = new Date(); 
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    let period = 'am';

    if (hours >= 12) {
        period = 'pm';
        hours = hours === 12 ? 12 : hours - 12;
    }
    hours = hours === 0 ? 12 : hours;

    const formattedDate = `${month} ${day}, ${year}`;

    const formattedTime = `${hours}:${minutes}:${seconds} ${period}`;

    if (date.toDateString() !== currentDate.toDateString()) {
        return `${formattedDate} ${formattedTime}`;
    } else {
        return formattedTime;
    }
}

// shows time including seconds if today, only date, not time if before today - says Today if Today
export const formatDateAndTimeSeconds = (input) => {
    try {
        const timestampMatch = input?.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}/);

        if (timestampMatch) {
            const timestamp = timestampMatch[0];
            const date = new Date(timestamp.replace(',', '.'));
            const now = new Date();
        
            const isBeforeToday = date < now;

            if (isNaN(date.getTime())) {
                console.error(`Error parsing timestamp: ${timestamp}`);
                return input; 
            }
            
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];
        
            const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
                ? ` ${date.getFullYear()}`
                : '';
            
            const formattedDate = isBeforeToday
                ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
                : '';
            
            const formatTime = (date) => {
                const hours = date.getHours() % 12 || 12;
                const minutes = date.getMinutes().toString().padStart(2, "0");
                const seconds = date.getSeconds().toString().padStart(2, "0");
                const amOrPm = date.getHours() >= 12 ? "pm" : "am";
                return `${hours}:${minutes}:${seconds}${amOrPm}`;
            };
            
            const formatDateAndTimeSeconds = isBeforeToday
                ? formattedDate
                : `${formattedDate} ${formatTime(date)}`;
          
            return formatDateAndTimeSeconds;
        }

        const date = new Date(input);
        const now = new Date();

        now.setHours(0, 0, 0, 0);
        
        const isBeforeToday = date < now;
        
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
            ? ` ${date.getFullYear()}`
            : '';

        const formattedDate = isBeforeToday
            ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
            : '';

        const formatTime = (date) => {
            const hours = date.getHours() % 12 || 12;
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");
            const amOrPm = date.getHours() >= 12 ? "pm" : "am";
            return `${hours}:${minutes}:${seconds}${amOrPm}`;
        };
        
        const formatDateAndTimeSeconds = isBeforeToday
            ? formattedDate
            : `${formattedDate} ${formatTime(date)}`;
      
        return formatDateAndTimeSeconds;
    } catch (error) {
        console.error(`Error in formatDateAndTimeSeconds: ${error}`);
        return input;
    }
};

// shows time if today, date AND time if before today - used in InstanceChat
export const formatTime = (date) => {
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amOrPm = date.getHours() >= 12 ? "pm" : "am";
    return `${hours}:${minutes}${amOrPm}`;
};

export const formatTimestamp = (input) => {
    try {
        if (input === undefined) {
            console.error('Error formatting timestamp: Input is undefined');
            return '';
        }

        const timestampMatch = input.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}/);

        if (timestampMatch) {
            const timestamp = timestampMatch[0];
            const date = new Date(timestamp.replace(',', '.'));
            const now = new Date();

            const isBeforeToday = date < now;

            if (isNaN(date.getTime())) {
                console.error(`Error parsing timestamp: ${timestamp}`);
                return input;
            }

            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
            ];

            const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
                ? ` ${date.getFullYear()}`
                : '';

            const formattedDate = isBeforeToday
                ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
                : '';

            const formatTimestamp = isBeforeToday
                ? formattedDate
                : `${formattedDate} ${formatTime(date)}`;

            return formatTimestamp;
        }

        const date = new Date(input);
        const now = new Date();

        now.setHours(0, 0, 0, 0);

        const isBeforeToday = date < now;

        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        const year = isBeforeToday && date.getFullYear() !== now.getFullYear()
            ? ` ${date.getFullYear()}`
            : '';

        const formattedDate = isBeforeToday
            ? `${monthNames[date.getMonth()]} ${date.getDate()}${year}`
            : '';

        const formatTimestamp = isBeforeToday
            ? formattedDate
            : `${formattedDate} ${formatTime(date)}`;

        return formatTimestamp;
    } catch (error) {
        console.error(`Error formatting timestamp: ${error.message}`);
        return input; 
    }
};
export const formatConversationTime = (timeString) => {
    // Split the time string (e.g., "00:00:14.346") into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');
    
    // Convert everything to seconds
    const totalSeconds = (+hours * 3600) + (+minutes * 60) + parseFloat(seconds);
    
    // Return the formatted time with two decimal places
    return totalSeconds.toFixed(2) + ' seconds';
}  



export const logoPath = "/layout/images/logo.svg";
export const logoIconPath = "/favicon.ico";
export const logoPathContrasted = "/layout/images/logo.svg";
export const honestyIconPath = "/layout/images/honesty-icon.png";
export const honestyIconConfusedPath = "/layout/images/honesty_icon_confused.png";
export const honestyIconOpenPath = "/layout/images/honesty_icon_open.png";
export const chatIconPath = "layout/images/chat-icon.svg";
export const phoneIconPath = "layout/images/phone-icon.svg";
export const instanceIconPath = "layout/images/instance-icon.svg";
export const platinumMedallionPath = "layout/images/medallions/platinum-badge.svg";
export const goldMedallionPath = "layout/images/medallions/gold-badge.svg";
export const silverMedallionPath = "layout/images/medallions/silver-badge.svg";
export const bronzeMedallionPath = "layout/images/medallions/bronze-badge.svg";
export const fileDownload = require('js-file-download');

export const getInitials = (firstName, lastName) => {
    try {
      if (lastName !== undefined) {
        return (
          (firstName ? firstName.charAt(0).toUpperCase() : '') +
          (lastName ? lastName.charAt(0).toUpperCase() : '')
        );
      } else {
        const names = firstName.split(' ');
        const firstInitial = names[0] ? names[0][0].toUpperCase() : '';
        const secondInitial = names[1] ? names[1][0].toUpperCase() : '';
  
        return `${firstInitial}${secondInitial}`;
      }
    } catch (error) {
      console.error('Error in getInitials:', error);
      return ''; 
    }
  }; 
  
  export function getColor(firstName, lastName) {
    const initials = getInitials(firstName, lastName);
    const baseHue = (hashString(initials) % 360 + 360) % 360; 

    return `hsl(${baseHue}, 70%, 60%)`;
}
  
  function hashString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
    }
    return hash;
  }

export const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
};

export const APP_HELPER = {
    logoPath: "/layout/images/logo.svg",
    logoPathContrasted: "/layout/images/logo.svg",
    fullName: function (first_name, last_name) {
        return first_name + ' ' + last_name;
    },
    getInitials: function(first_name, last_name) {
        return first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase();
    },
    toastTypes: {
        success: 'success',
        error: 'error',
        info: 'info',
        warn: 'warn'
    },
    getError: function(error) {
        const errorMessage = "API Error, please try again.";

        if (error.name === "Fetch User") {
            return error.message;
        }

        if (!error.response) {
            console.log(error);
            console.error(`API ${error.config.url} not found`);
            return  errorMessage;
        }

        if (process.env.NODE_ENV === "development") {
            console.log(error);
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        }

        if (error.response.data && error.response.data.errors) {
            return error.response.data.errors;
        }

        return errorMessage;
    },
    isEmpty: function(value){
        return !(value !== null && value !== undefined && value !== '');
    },
    jsUcfirst: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatPhoneNumber: function (value) {
        let cleaned = ('' + value).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + ' ('+ match[2] +')' + '-' + match[3]+ '-' + match[4];
        }
        //console.log(phoneNumberString);
        return null;
    },
    onlyNumbers: function(evt){
        //console.log(evt);
        if( !/\d/.test(evt.key) && evt.key === '.' || evt.key === 'e') return evt.preventDefault();
    },
    debounce: function (func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    },
    breadModels: {
        Account: 'Account',
        User: 'User',
        Action: 'Action',
        CallData: 'Call-Data',
        Instance: 'Instance',
        Chat: 'Chat',
        Feedback: 'Feedback',
        Contact: 'Contact',
        Recording: 'Recording',
        Role: 'Role',
        SipCreds: 'Sip-Credential',
        Transcript: 'Transcript',
        Agent: 'Agent',
        Tool: 'Tool',
        Bot: 'Bot',
        LLM: 'LLM'
    },
};

export const AUTH_HELPER = {
    canUser: function (permissions, action, model, level = null) {
        let can = false;
        console.log("AUTH_HELPER.canUser");
        console.log(action + ' - ' + model);
        console.log(permissions);
        if (Object.prototype.hasOwnProperty.call(permissions[model], action)) {
            if (permissions[model][action]) {
                can = true;
                if (level !== null) {
                    can = (can && permissions[model]['level'] >= level);
                }
            }
        }
        return can;
    },

    superAdmin: function (user, model) {
        let can = false;
        if(Object.prototype.hasOwnProperty.call(user, model) && user[model] === 9) {
            can = true;
        }
        return can;
    },

    regularAdmin: function (user, model) {
        let can = false;
        if (Object.prototype.hasOwnProperty.call(user, model) && user[model] === 8) {
            can = true;
        }
        return can;
    },
    authActions: {
        browse: 'browse',
        read: 'read',
        edit: 'edit',
        add: 'add',
        delete: 'delete'
    },
    authModels: {
        Account: 'Accounts',
        User: 'Users',
        Action: 'Actions',
        CallData: 'Call-Data',
        Instance: 'Instances',
        Recording: 'Recordings',
        Role: 'Roles',
        Chat: 'Chat',
        Feedback: 'Feedback',
        Contact: 'Contacts',
        SipCreds: 'Sip-Credentials',
        Transcript: 'Transcripts',
        Reporting: 'Reporting',
        Agent: 'Agents',
        AgentInstructions: 'AgentInstructions',
        Tool: 'Tools',
        Bot: 'Bots',
        LLM: 'LLMs'
    },
}